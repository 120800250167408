export const colors = {
  red: '#F5222D',
  volcano: '#FA541C',
  orange: '#FA8C16',
  gold: '#FAAD14',
  yellow: '#FADB14',
  lime: '#A0D911',
  green: '#52C41A',
  cyan: '#13C2C2',
  blue: '#1890FF',
  geekBlue: '#2F54EB',
  purple: '#722ED1',
  magenta: '#EB2F96',
  accent: '#0099FF',
};
